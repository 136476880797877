import { graphql, Link } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import React from "react"
import { FaAngleLeft, FaAngleRight } from "react-icons/fa"
import Card from "../components/Card"
import SEO from "../components/seo"
import "./bloglist.sass"

const BlogList = ({ data, pageContext }) => (
  <>
    <SEO title="Blog" />
    <div className="spacer-3" />
    <div className="container">
      <p>
        <AniLink
          swipe
          duration={0.3}
          entryOffset={100}
          direction="right"
          to="/"
        >
          <FaAngleLeft /> Home
        </AniLink>
      </p>
      <h1>Blog</h1>
    </div>
    <BackgroundImage
      fluid={[
        data.bannerImg.sm.fluid,
        {
          ...data.bannerImg.md.fluid,
          media: `(min-width: 768px)`,
        },
        {
          ...data.bannerImg.lg.fluid,
          media: `(min-width: 992px)`,
        },
        {
          ...data.bannerImg.xl.fluid,
          media: `(min-width: 1200px)`,
        },
      ]}
      backgroundColor={`#acaa9b`}
      className="blog-banner"
      style={{ backgroundPosition: "center 20%" }}
    />
    <div className="container">
      <div className="blog-grid">
        {data.allFile.nodes.map((node, index) => {
          const sources = [
            node.childMarkdownRemark.frontmatter.thumbnail.oneCol.fluid,
            {
              ...node.childMarkdownRemark.frontmatter.thumbnail.twoCol.fluid,
              media: `(min-width: 768px)`,
            },
            {
              ...node.childMarkdownRemark.frontmatter.thumbnail.threeCol.fluid,
              media: `(min-width: 992px)`,
            },
          ]
          return (
            <Link key={index} to={`/blog/${node.name}`} className="blog-card">
              <Card img={sources} color={true}>
                <h3>{node.childMarkdownRemark.frontmatter.title}</h3>
                <p>
                  <strong>
                    {node.childMarkdownRemark.timeToRead}-minute read
                  </strong>
                </p>
                <p>{node.childMarkdownRemark.frontmatter.description}</p>
              </Card>
            </Link>
          )
        })}
      </div>
      <div className="blog-pagination">
        {pageContext.currentPage === 1 ? (
          <span className="blog-pagination-prevnext text-left">
            <FaAngleLeft /> Previous
          </span>
        ) : (
          <span className="blog-pagination-prevnext text-left">
            <AniLink
              fade
              duration={0.3}
              to={
                pageContext.currentPage === 2
                  ? `/blog`
                  : `/blog/${pageContext.currentPage - 1}`
              }
            >
              <FaAngleLeft /> Previous
            </AniLink>
          </span>
        )}
        <div className="blog-pagination-list text-center">
          {Array.from({ length: pageContext.numPages }, (_, i) => (
            <AniLink
              fade
              duration={0.3}
              key={i}
              className="blog-pagination-link"
              activeClassName="active"
              to={i === 0 ? `/blog` : `/blog/${i + 1}`}
            >
              {i + 1}
            </AniLink>
          ))}
        </div>
        {pageContext.currentPage === pageContext.numPages ? (
          <span className="blog-pagination-prevnext text-right">
            Next <FaAngleRight />
          </span>
        ) : (
          <span className="blog-pagination-prevnext text-right">
            <AniLink
              fade
              duration={0.3}
              to={`/blog/${pageContext.currentPage + 1}`}
            >
              Next <FaAngleRight />
            </AniLink>
          </span>
        )}
      </div>
    </div>
    <div className="spacer-3" />
  </>
)

export const postQuery = graphql`
  query BlogListSkipLimit($skip: Int!, $limit: Int!) {
    bannerImg: file(relativePath: { eq: "dsc00040.jpg" }) {
      sm: childImageSharp {
        fluid(quality: 100, maxWidth: 576) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
      md: childImageSharp {
        fluid(quality: 100, maxWidth: 768) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
      lg: childImageSharp {
        fluid(quality: 100, maxWidth: 992) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
      xl: childImageSharp {
        fluid(quality: 100, maxWidth: 1200) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    allFile(
      filter: { sourceInstanceName: { eq: "blog" } }
      sort: { fields: childMarkdownRemark___frontmatter___date, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      nodes {
        childMarkdownRemark {
          timeToRead
          frontmatter {
            description
            title
            thumbnail {
              oneCol: childImageSharp {
                fluid(quality: 100, maxWidth: 540) {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
              }
              twoCol: childImageSharp {
                fluid(quality: 100, maxWidth: 352) {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
              }
              threeCol: childImageSharp {
                fluid(quality: 100, maxWidth: 370) {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
              }
            }
          }
        }
        name
      }
      totalCount
    }
  }
`

export default BlogList
